.faveCard {
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  border: 2px solid gray;
  border-radius: 20px;
  height: 375px;
  max-width: 85vh;
  /* padding: 20px; */
  position: relative;
  width: 270px;
  display: flex;
  align-items: flex-end;
}

.noFaveCard {
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  border: 2px solid gray;
  border-radius: 20px;
  height: 10vh;
  max-width: 85vh;
  padding: 20px;
  position: relative;
  width: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faveCard > h3 {
  position: aboslute;
  bottom: 10px;
  color: white;
}
.faveList_housePic {
  width: 250px;
  /* height: 40vh; */
}
.faveCards__container {
  display: flex;
  justify-content: center;
  /* height: 65vh; */
  margin-top: 5vh;
}

.swipe {
  position: absolute;
}
.faveDetailFlex {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.faveDetailFlexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.faveDetailCard {
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  border: 2px solid gray;
  border-radius: 20px;
  height: 100%;
  max-width: 85vh;
  padding: 20px;
  position: relative;
  width: 250px;   
}

.faveCard_address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.faveCard > h3 {
  position: aboslute;
  bottom: 10px;
  color: white;
}

.faveDetailCard__container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.faveMaps {
  width: 250px;
  height: 150px;
  margin: 5px 0 15px;
}

.faveCarousel {
  height: 0%;
}
.faveCardTitle {
  display:flex;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9374124649859944) 70%, rgba(255,255,255,0) 100%);
  border-radius: 0 0 15px 15px ;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  /* border: 2px solid white; */
  /* height: 50px; */
}