.rowButtons {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  /* max-width: 85vh; */
  width: 250px;
  /* background-color: white; */
  /* box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, .3); */
  /* border-radius: 20px; */
}

.rowButtons .MuiIconButton-root {
  background-color: white;
  box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, .3) !important;
}

.buttons_repeat {
  padding: 3vw !important;
  color: #f5b748 !important;
}

.buttons_right {
  padding: 20px !important;
  color: #76e2b3 !important;
}

.buttons_left {
  padding: 20px !important;
  color: #ec5e64 !important;
}