.userCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* flex-direction: row;
  flex-wrap: wrap; */
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(0, 0, 0, .25);
  border: 2px solid gray;
  border-radius: 20px;
  height: fit-content;
  /* max-width: 85vh; */
  padding: 20px;
  position: relative;
  width: 250px;
}
.userCard2 {
  /* display: flex; */
  /* justify-content: space-between; */
  /* flex-direction: row;
  flex-wrap: wrap; */
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(0, 0, 0, .25);
  border: 2px solid gray;
  border-radius: 20px;
  height: fit-content;
  max-width: 85vh;
  padding: 20px;
  position: relative;
  width: 250px;
}

/* .test {
  border: 1px solid black;
} */

.border {
  border: 2px solid black;
}

.userFlexItem {
  display: flex;
  justify-content: space-around;
}
.userCard__statButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around ;
  padding: 5px 0;
}
.userCard > h3 {
  position: aboslute;
  bottom: 10px;
  /* color: white; */
}
.userCard__searchList {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 0;
}
.buttons2 {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: center;
}

.userCard__container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  /* height: 65vh; */
}
.userCard__container2 {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  height: 65vh;
}

.userProfile_container {
  position: absolute;
}

.userForm__container {
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
  /* margin-top: 5vh; */
}
.userUpload__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 5vh; */
}
.userProfile_avatar {
  width: 140px;
  height: 140px;
  border-radius: 75px;
  padding-bottom: 5px;
}

.userForm {
  /* border: 2px solid black; */
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(0, 0, 0, .25);
  border: 2px solid gray;
  border-radius: 20px;
  height: 50vh;
  /* max-width: 85vh; */
  padding: 20px;
  position: relative;
  width: 250px;
}

.userForm-group {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-content: center;
}
.userForm-group2 {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  padding: 5px 0 5px;
  width: 250px;
  justify-content: center;
}
.uploadButton {
  padding: 1vh;
  width: 100%;
  align-self: flex-start;
}
.userFormFlex {
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
}

.userForm_columnFlex {
  display: flex;
  flex-direction: column;
  align-items: center
}

.deleteSearchButton {
  padding: 5px !important;
}

#userTypeId {
  margin-right: 0px;
}