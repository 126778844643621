.homeCard {
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  border: 2px solid gray;
  border-radius: 20px;
  /* height: 450px; */
  max-width: 85vh;
  padding: 20px;
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.homeIntroCard {
  background-position: center;
  background-size: cover;
  background-color: seashell;
  border: 2px solid gray;
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  
  border-radius: 20px;
  /* height: 25vh; */
  max-width: 85vh;
  padding: 20px;
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homeCard > h3 {
  position: absolute;
  bottom: 10px;
  /* color: white; */
}

.homeCard__container {
  display: flex;
  justify-content: center;
  margin-top: 1vh;
  /* height: 65vh; */
}

.homeProfile_container {
  display: flex;
  position: absolute;
  align-content: space-between;
}

.homeCard__greeting {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.greetingText {
  padding-bottom:1vh
}
.greetingTextSmall {
  display: flex;
  justify-content: center;
  padding-bottom:.5vh
}

.homeCard__userAvatar {
  width: 17vh;
  /* height: 17vh; */
  border-radius: 75px;
  padding-bottom: 1vh;
}

.homeCard__buttons {
  display: flex;
  justify-content: space-between;
}

.homeIntro_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5vh 0;
}