.searchCard {
  display: flex;
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.05);
  border: 2px solid gray;
  border-radius: 20px;
  height: 300px;
  max-width: 85vh;
  /* padding: 20px; */
  position: relative;
  width: 270px;
}

.searchCard > h3 {
  position: aboslute;
  bottom: 10px;
  color: white;
}

.searchCard__container {
  display: flex;
  justify-content: center;
  /* margin-top: 5vh; */
  /* height: 65vh; */
}
.searchCard__container2 {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}
.sear

.bordersearch {
  display:flex;
  justify-content: space-around;
  
  /* border: 2px solid white; */
}
.bedsPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bedsBaths {
  display:flex;
  justify-content: space-around;
  padding-bottom: .5vh;
  align-items: center;
  /* border: 2px solid white; */
}
.beds {
  padding-right: 1vh;
  /* border: 2px solid white; */
}
.searchCardTitle {
  display:flex;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9374124649859944) 70%, rgba(255,255,255,0) 100%);
  border-radius: 0 0 15px 15px ;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 5px;
  /* border: 2px solid white; */
  height: 60px
}

.swipe {
  position: absolute;
}

/* .searchForm_inputs {
  display: flex;
  flex-wrap: wrap;
  
} */

.searchForm {
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  border: 2px solid gray;
  border-radius: 20px;
  height: 100%;
  max-width: 85vh;
  padding: 20px;
  position: relative;
  width: 250px;
}

.SearchForm-control {
  display: block;
  width: 100%;
  /* height: calc(1em + .75rem + 2px); */
  /* padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}

.SearchFormDropDown-control {
 width: 25% 
}

.searchForm > h3 {
  position: aboslute;
  bottom: 10px;
  color: white;
}

.searchForm__Container {
  display: flex;
  justify-content: center;
  margin-top: 1vh;
}

.form-group-select {
  display: flex;
}

.searchForm_inputs > fieldset {
  padding: 1rem 0 0;
}

.inputRadio {
  padding-top: 0;
}
