.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, .75) ;
  
}

.navbarButtons {
  color:rgba(255, 255, 255, .75) !important;
}

.navbarButtonGone {
  color: #663399 !important
}

.navbarLogo {
  height: 30px;
  object-fit: contain;
  background-color: goldenrod;
}

.navbar_end {
  align-self: flex-end;
}
.navbar_center {
  align-self: center;
}