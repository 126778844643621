.h1, h1 {
    font-size: 2.5rem;
}

fieldset {
    min-width: 0;
    padding: 1rem 0;
    margin: 0;
    border: 0;
    width: 200px
}

.LoginCard {
    display: flex;
    background-position: center;
    background-color: rgb(255, 255, 255, .75);
    background-size: cover;
    box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.2);
    border: 2px solid gray;
    border-radius: 20px;
    height: 225px;
    /* max-width: 85vh; */
    padding: 20px;
    position: relative;
    width: 250px;
  }
.LoginCard2 {
    display: flex;
    background-position: center;
    background-color: rgb(255, 255, 255, .75);
    background-size: cover;
    box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.2);
    border: 2px solid gray;
    border-radius: 20px;
    height: 360px;
    /* max-width: 200px; */
    padding: 20px;
    position: relative;
    width: 250px;
  }



.form--login {
    display: flex;
    flex-direction: column;

    margin: 0 auto;
    text-align: left;
}

/* .form--login > fieldset > input[type="email"],
.form--login > fieldset > input[type="password"] {
    width: 25em;
} */

.label--login {
    width: 8rem;
    display: inline-block;
}

.form-control {
    display: block;
    
    width: 97%;
    /* height: calc(1em + .75rem + 2px); */
    /* padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box; */
    /* border: 1px solid #ced4da; */
    /* border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}
.radios {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.radio {
    display: flex;
}
.register_buttons {
    display: flex;
    justify-content: space-between;
}
/* .register_button {
    margin-right: 1vh;
} */

.container--login {
    text-align: center;
}


.form--login {
    background-position-x: right;
    /* background-image: url(logo.png); */
    z-index: 1;
    /* min-height: 25rem; */
    /* min-width: 35rem; */
    /* background-color: hsla(0,0%,100%,0.40); */
    background-blend-mode: overlay;
    background-repeat: no-repeat;
}

.button--close {
    position: relative;
    bottom: -2rem;
}

.dialog {
    min-width: 15rem;
    min-height: 5rem;
}


  .LoginCard__container {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    height: 65vh;
  }