.chatCard {
  background-position: center;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  border: 2px solid gray;
  border-radius: 20px;
  height: fit-content;
  /* max-width: 85vh; */
  padding: 20px;
  position: relative;
  width: 250px;
}

.convo {
  margin: 0 auto;
  color: #ffffff;
}

.chatCard > h3 {
  position: aboslute;
  bottom: 10px;
  color: white;
}

.chatCard__img {
  width: 4vh;
  /* height: 4vh; */
}
.chatList_housePic {
  width: 50vh;
  /* height: 40vh; */
}
.chatCards__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top: 2vh;
  height: 65vh;
} 
.chatCards__container > h3 {
  color: rgba(255, 255, 255, 0.75);
  padding-bottom: 1.5vh;
}
.chatFlex {
  display: flex;
}
.chatMessage {
  padding-bottom: 2vh;
}
.chatMessageText {
  padding-top: .5vh;
  width: 190px;
}
.chatFlexMessage {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}
.chatFlexMessageTime {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 190px;
  /* justify-content: space-between; */
}
.chatMessagePadding {
  padding-left: 1vh;
}
.chatMessageColor {
  color: rgb(82, 82, 82)
}
.chatFlexStart {
  display: flex;
  background-color:rgba(14, 148, 189, 0.432);
  flex-direction: column;
  border-radius: 20px 20px 20px 0;
  /* justify-content: flex-end; */
}
.chatFlexEnd {
  display: flex;
  border-radius: 20px 20px 0px 20px;
  flex-direction: column;
  background-color:rgba(46, 196, 23, 0.432);
  justify-content: flex-end;
}
.end {
  
  justify-content: flex-end;
}
.endPadding{
  
  padding-right: 1vh
}
.startPadding {
  padding-left: 1vh
}
.swipe {
  position: absolute;
}
.buttonPadding {
  padding: 5px !important;
}
.chatDetailCard {
  background-position: center;
  background-size: cover;
  background-color: rgb(255, 255, 255, .75);
  box-shadow: 0px 18px 25px 0 rgba(0, 0, 0, .25);
  border: 2px solid gray;
  border-radius: 20px;
  height: 100%;
  max-width: 85vh;
  padding: 20px;
  position: relative;
  width: 50vh;   
}

.chatCard_address {
  display: flex;
  justify-content: space-between;
}

.chatCard > h3 {
  position: aboslute;
  bottom: 10px;
  color: white;
}

.chatDetailCard__container {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.chatCarousel {
  height: 0%;
}

.messageForm {
  display: flex;
  justify-content: space-between;
  background-position: center;
  background-color: rgb(255, 255, 255, .75);
  background-size: cover;
  box-shadow: 0px 18px 25px 0 rgba(255, 255, 255, 0.25);
  border: 2px solid gray;
  border-radius: 20px;
  height: 50%;
  /* max-width: 85vh; */
  padding: 1vh 2vh 2.5vh 2vh ;
  position: relative;
  width: 250px;
  /* margin-bottom: 2vh; */
}

.messageForm-control {
  display: block;
  width: 35vh;
  /* height: calc(1em + .75rem + 2px); */
  /* padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}

.messageFormDropDown-control {
 width: 25% 
}

.messageForm > h3 {
  position: aboslute;
  bottom: 10px;
  color: white;
}

.messageForm__Container {
  display: flex;
  justify-content: center;
  margin-top: 1vh;
}

.sendBtn {
  height: 4vh;
  justify-content: center;
  padding-right: 0;
}

.form-group {
  
  /* flex-basis: 40%; */
}